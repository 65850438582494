import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout.jsx';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import PhotoLessHero from '../../components/hero/photoless-hero/photoless-hero.jsx';
import Logos from '../../components/logos/logos.jsx';
import SEO from "../../components/seo"

export const query = graphql`
  query Localesa($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function About() {
    const translate = useTranslation().t;
    return (
        <Layout>
          <SEO title="About" />
            <PhotoLessHero
                title={translate("about-us-title")} 
                description={translate("about-us-subtitle")}
                backgroundColor = "#0057b7"
            />
            <div class="inner" style={{ width: "90%", marginTop: "1rem", paddingBottom: "1rem" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="269.692" viewBox="0 0 1419.858 769.692">
                      <g id="Clinic_for_Ukraine" data-name="Clinic for Ukraine" transform="translate(0.004 0.02)">
                          <path id="Path_1" data-name="Path 1" d="M894.259,632.409V765.364a140.449,140.449,0,1,1-280.9,0V607.125c112.365-12.167,199.433-107.672,199.433-222.845V250.389A27.585,27.585,0,0,0,784.7,222.273H723.853V220.4a28.091,28.091,0,1,0-56.182,0v59.926a28.088,28.088,0,1,0,56.176,0l-.006-1.872h32.771v105.8c0,92.7-75.888,168.547-168.534,168.547S419.543,476.915,419.543,384.256V278.481h30.9v1.872a28.088,28.088,0,1,0,56.176,0l-.006-59.926a28.088,28.088,0,1,0-56.176,0l.006,1.847h-58.99a27.585,27.585,0,0,0-28.091,28.091V384.281c0,113.3,84.273,207.869,193.817,222.845V765.364C557.172,873.964,645.21,962,753.81,962s196.638-88.038,196.638-196.638l-.006-132.955Z" transform="translate(-363.36 -192.33)" fill="#1e4cb0"/>
                          <path id="Path_2" data-name="Path 2" d="M894.259,632.409V765.364a140.449,140.449,0,1,1-280.9,0V607.125c112.365-12.167,199.433-107.672,199.433-222.845V250.389A27.585,27.585,0,0,0,784.7,222.273H723.853V220.4a28.091,28.091,0,1,0-56.182,0v59.926a28.088,28.088,0,1,0,56.176,0l-.006-1.872h32.771v105.8c0,92.7-75.888,168.547-168.534,168.547S419.543,476.915,419.543,384.256V278.481h30.9v1.872a28.088,28.088,0,1,0,56.176,0l-.006-59.926a28.088,28.088,0,1,0-56.176,0l.006,1.847h-58.99a27.585,27.585,0,0,0-28.091,28.091V384.281c0,113.3,84.273,207.869,193.817,222.845V765.364C557.172,873.964,645.21,962,753.81,962s196.638-88.038,196.638-196.638l-.006-132.955Z" transform="translate(-363.36 -192.33)" fill="#005bbb"/>
                          <path id="Path_4" data-name="Path 4" d="M930.469,478.62c0,57.055-45.533,103.815-102.55,103.815S722.84,535.637,722.84,478.62" transform="translate(-268.17 -116.516)" fill="#ffd500"/>
                          <path id="Path_5" data-name="Path 5" d="M0,39.6Z" transform="translate(510.524 326.775)" fill="#fff"/>
                          <g id="Group" transform="translate(742.854 239.102)">
                          <text id="Digital_Clinic_" data-name="Digital Clinic  " transform="translate(0 98)" fill="#005bbb" font-size="105" font-family="FrutigerLTStd-Bold, Frutiger LT Std" font-weight="700"><tspan x="0" y="0" xmlSpace="preserve">Digital Clinic  </tspan></text>
                          <text id="for_Ukraine" data-name="for Ukraine" transform="translate(0 227.009)" fill="#ffd500" font-size="105" font-family="FrutigerLTStd-Bold, Frutiger LT Std" font-weight="700"><tspan x="0" y="0">for Ukraine</tspan></text>
                          </g>
                          <path id="Path_6" data-name="Path 6" d="M930.469,582.435c0-57.055-45.533-103.814-102.55-103.814S722.84,525.418,722.84,582.435" transform="translate(-268.17 -216.435)" fill="#005bbb"/>
                          <path id="Path_8" data-name="Path 8" d="M557.178,765.364C557.172,873.964,645.21,962,753.81,962Z" transform="translate(-363.36 -192.33)" fill="#005bbb"/>
                      </g>
                </svg>
                <Typography sx={{ marginTop: "1rem", marginBottom: "2rem", marginLeft: "auto", marginRight: "auto", whiteSpace: "pre-line" }} >
                    {translate("about-us-intro-text")}
                    <Typography variant="h4" gutterBottom sx={{marginTop: "1rem"}}>{translate("what-can-we-do")}</Typography>
                    {translate("what-can-we-do-content")}
                    <Typography variant="h4" gutterBottom sx={{marginTop: "1rem"}}>{translate("who-we-are")}</Typography>
                    {translate("who-we-are-content")}
                </Typography>
                
                <Typography align="center" variant="h4" sx={{ marginBottom: "1rem" }}>
                    {translate("supported-by")}
                </Typography>
                <Logos/>                
            </div>
        </Layout>
  )
}
