import React from "react";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { hero__title } from "./photoless-hero.module.scss";

export default function PhotoLessHero({ title, description, backgroundColor }) {
    return (
        <section
            id="hero"
            className="block hero-block outer"
            style={{ backgroundColor }}
        >
            <div className="inner">
                <Typography
                    className={hero__title}
                    align="center"
                    variant="h5"
                    color="secondary"
                >
                    {title}
                </Typography>
                <Typography
                    align="center"
                    sx={{ marginTop: "1em", marginBottom: "1em" }}
                    variant="h4"
                    color="secondary"
                    component="div"
                >
                    {description}
                </Typography>
                <motion.button
                    style={{ border: "none", backgroundColor: "#0092ff" }}
                    whileHover={{
                        scale: 1.1,
                    }}
                    whileTap={{ scale: 0.9 }}
                ></motion.button>
            </div>
        </section>
    );
}
